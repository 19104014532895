import React from 'react'
import SkillCard from './SkillCard.jsx'
import { Skils } from '../../constants.js'
import SectionHeadding from '../UI/SectionHeadding.jsx'
import ReactGA from 'react-ga4';

ReactGA.send({
  hitType: "pageview",
  page: "/Skills",
  title: "Skills",
});

const Skills = () => {


  return (
    <div class="container mx-auto p-4">
      <SectionHeadding title="Knowledge Areas" subtitle="" />

    <div className='grid gap-4 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 mt-5 justify-items-center'>
      {
        Skils.map((skill) => (
          <SkillCard  name={skill.name} imageid={skill.cloudinary}  key={skill.cloudinary}/>
        ))
      }
      
    </div>
    </div>
  )
}

export default Skills
