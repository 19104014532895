import { NavLink } from "react-router-dom";
import "./Herosec.css";
import { FaInstagram, FaLinkedin, FaGithub } from "react-icons/fa";
import ReactGA from "react-ga4";
// import { useEffect, useRef, useState } from "react";
const MyName = "Pavan";

ReactGA.send({
  hitType: "pageview",
  page: "/",
  title: "Home",
});


const handleClickToAnalytics = (platform, cat) => {
  ReactGA.event({
      category: cat,
      action: 'Click',
      label: platform,
  });
};

const HeroSec = () => {
  // const ref = useRef(0);
  // const [ScreenName, setScreenName] = useState('');

  // useEffect(() => {
  // 	const interval = setInterval(() => {
  // 		if (ref.current < MyName.length) {
  // 			ref.current++;
  // 			setScreenName(() => ScreenName + MyName[ref.current - 1]);
  // 		}
  // 	}, 50);
  // 	return () => clearInterval(interval);
  // }, [ScreenName]);
  return (
    <>
      <div
        className="w-4/5 md:w-4/5 lg:w-auto"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate3d(-50%,-50%,0)",
        }}
      >
        <div className="flex flex-col p-2 ">
          <h1 className="font-bold text-5xl h-auto leading-normal lg:text-8xl lg:leading-normal font-sans">
            Hey, I'm&nbsp;<span className="Text-pinkYellow">{MyName} </span>
          </h1>
          <h2 className="text-2xl lg:text-4xl font-bold ">
            a software developer from Bengaluru.
          </h2>
          <p
            className=" hidden md:block md:text-xs lg:text-sm leading-normal pt-3"
            style={{ fontSize: "1.2rem" }}
          >
            Trying to cause apositive impact through technology.
          </p>
          <div className="flex items-center gap-4 pt-2 md:pt-6">
            <NavLink to="/Resume" onClick={() => handleClickToAnalytics('Resume', 'Resume')} >
              <button className="bg-[#fcae30] text-white px-4 py-2 rounded-md  font-medium ">
                Resume
              </button>
            </NavLink>
            <div className="flex items-center gap-4 ">
              <a
                href="https://www.linkedin.com/in/pavan-s-335658190/"
                target="_blank"
                rel="noopener noreferrer"                
              >

                <FaLinkedin className="text-2xl  text-gray-500 Iconlinkedin-in" onClick={() => handleClickToAnalytics('LinkedIn', 'Social Links')}/>
              </a>
              <a
                href="https://github.com/pavanshetty-in"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleClickToAnalytics('GitHub', 'Social Links')}
              >
                <FaGithub className="text-2xl text-gray-500 hover:text-white" />
              </a>
              <a
                href="https://www.instagram.com/pavanshetty.in/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleClickToAnalytics('Instagram', 'Social Links')}
              >
                <FaInstagram className="text-2xl text-gray-500 Iconinstagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HeroSec;
