// import logo from './logo.svg';
// import './App.css';
import Experience from './components.jsx/Experience/Experience.jsx';
import Background from './components.jsx/HeroSec/Background.jsx';
import HeroSec from './components.jsx/HeroSec/HeroSec.jsx';
import NavBar from './components.jsx/NavBar/NavBar.jsx';
import Skills from './components.jsx/Skills/Skills.jsx';
import Projets from './components.jsx/Projects/Projects.jsx';
import Resume from './components.jsx/Resume/Resume.jsx';
import { Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';

ReactGA.initialize('G-LYPTL6X20Y');

function App() {

  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);

  return (
    
    < >
    <NavBar />
    <Routes>
      <Route path='/' element={ <><Background /> <HeroSec/></>}/>
      <Route path='/Experience' element={ <Experience />}/>
      <Route path='/Projects' element={ <Projets />}/>
      <Route path='/Skills' element={ <Skills />}/>
      <Route path='/Resume' element={ <Resume />}/>
    </Routes>

    </>
  );
}

export default App;
