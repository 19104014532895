import React from 'react'

const SectionHeadding = ({title, subtitle}) => {
  return (
    <div className='flex flex-col w-full items-center'>
      <h2 className='text-3xl font-bold text-white HeadingText-pinkYellow'>{title}</h2>
      {subtitle && <p className='!text-sm text-gray-500'>{subtitle}</p>}
    </div>
  )
}

export default SectionHeadding
