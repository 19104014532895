import React from "react";
import { ResumeDriveUrL } from "./../../constants.js";

const Resume = () => {
  return (
    <div className="h-screen w-full flex justify-center">
      <iframe title="Resume Preview" src={ResumeDriveUrL} className="w-full h-4/5 p-4 md:w-4/5 lg:w-3/5 lg:h-full"  allow="autoplay"></iframe>
    </div>
  );
};

export default Resume;
