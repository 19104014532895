import React from "react";
import { AdvancedImage } from "@cloudinary/react";
import { GetImg } from "../../Util/GetImg.js";

const SkillCard = ({ name, imageid }) => {

  let img = GetImg(`portfolio/Skills/${imageid}`)

  return (
    <div
      className=" flex flex-col w-40 md:w-48 xl:w-56 SkillCardShadow justify-items-center justify-center items-center py-4 xl:py-6"
      style={{ backgroundColor: "#0a0113" }}
    >
      <AdvancedImage
        className="max-w-36 justify-center px-7  py-2 xl:py-4"
        cldImg={img}
      />
      {/* <img className='max-w-36 justify-center px-7  py-2 xl:py-4' src="https://res.cloudinary.com/drlaziud8/image/upload/v1724901434/portfolio/Skills/C_ioozjd.png" alt="C Programming" /> */}
      <p className="text-white text-base pb-2 items-center justify-items-center uppercase">
        {name}
      </p>
    </div>
  );
};

export default SkillCard;
