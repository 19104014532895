import React from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { experiences } from "./../../constants.js";
import SectionHeadding from '../UI/SectionHeadding.jsx'
import ReactGA from 'react-ga4';

ReactGA.send({
  hitType: "pageview",
  page: "/Experience",
  title: "Experience",
});

const Experience = () => {

  return (
    <div
      className="experience w-screen text-white pt-4  overflow-x-hidden"
      id="experience"
    >
      <SectionHeadding title="My Timeline" subtitle="Professional Experience" />
      <VerticalTimeline className="mt-1">
        {experiences.map((experience) => (
          <VerticalTimelineElement
            className="relative vertical-timeline-element--work"
            contentStyle={{ background: "#1d1836", color: "#fff" }}
            contentArrowStyle={{ borderRight: "7px solid  #232631" }}
            date={experience.duration}
            iconStyle={{ background: "#fff" }}
            icon={
              <a
                className="flex justify-center items-center w-full h-full"
                href={experience.url}
                target="_blank" rel="noreferrer"
              >
                <img
                   src={experience.logo}
                  alt={experience.company}
                  className="object-contain rounded-full"
                />
              </a>
            }
          >
            <div>
              <h3 className="text-white text-[24px] font-bold">
                {experience.role}
              </h3>
              <p
                className="text-secondary text-[16px] font-semibold"
                style={{ margin: 0 }}
              >
                {experience.company}
              </p>
            </div>

            <ul className="mt-5 list-disc ml-5 space-y-2">
              {experience.points.map((point, index) => (
                <li
                  key={`experience-point-${index}`}
                  className="text-white-100 text-[14px] pl-1 tracking-wider"
                >
                  {point}
                </li>
              ))}
            </ul>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
};

export default Experience;
