import React from 'react'
import ProjectCard from './ProjectCard'
import { projects } from '../../constants'
import SectionHeadding from '../UI/SectionHeadding.jsx'
import ReactGA from 'react-ga4';

ReactGA.send({
  hitType: "pageview",
  page: "/Projects",
  title: "Projects",
});
const Projets = () => {
  return (
    <div class="container mx-auto p-4">
      <SectionHeadding title="My Projects" subtitle="Highlighted Projects I've Built" />
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
          {projects.map((project) => (
             <ProjectCard key={project.name} name={project.name} shortDescription={project.shortDescription} techStack={project.techStack} codeUrl={project.codeUrl} imageURL={project.imageURL} />
          ))}
      </div>
    </div>
  )
}

export default Projets
