
export const experiences = [
    {
        'company': 'Oracle Cerner Healthcare Solutions',
        'role': 'Software Developer',
        'duration': 'Aug 2022 - Present',
        'logo': 'https://res.cloudinary.com/drlaziud8/image/upload/v1725191459/portfolio/Experience/OracleCernerLogo.png',
        'points': [
            'Developed 🛠️ and maintained Eggplant automation test scripts across multiple pipelines 🔄.',
            'Created 🐍 a Python project to streamline Jenkins job configuration ⚙️, enhancing efficiency and productivity 📈.',
            'Led 🚀 Continuous Availability validation, improving test coverage by 50% 💪 and reducing defects 📉.',
            'Maintained and enhanced an internal Python Django dashboard 🖥️ for real-time testing status ⏱️ and management support.',
            'Engineered ⚙️ dynamic test scripts integrating Eggplant 🛠️, API Tests 🔍, and Selenium WebDriver for comprehensive validation.'
        ],
        'url': 'https://www.oracle.com/in/health/',
    },
    {
        'company': 'Cerner Healthcare Solutions',
        'role': 'Software Intern',
        'duration': 'Feb 2022 - Aug 2022',
        'logo': 'https://res.cloudinary.com/drlaziud8/image/upload/v1725191459/portfolio/Experience/CernerLogo.jpg',
        'points': [
            'Acquired expertise 🧠 in load and performance testing with Gatling Crank 🚀, and improved processes 🔄.',
            'Executed and maintained automation test scripts 🧪 across pipelines 🔄, diagnosing failures and identifying defects 🛠️.'
        ],
        'url': 'https://www.oracle.com/in/health/',
    },
    {
        'company': 'Learn Basics',
        'role': 'Software Intern',
        'duration': 'Nov 2021 - Feb 2022',
        'logo': 'https://res.cloudinary.com/drlaziud8/image/upload/v1725191480/portfolio/Experience/LBLogo.png',
        'points': [
            'Contributed to the development of an internal dashboard using Ruby on Rails 🛠️.',
            'Designed and developed client analytics webpages 🎨 using Figma and React.js 💻'
        ],
        'url': 'https://learnbasics.fun/',
    },
    {
        'company': 'Verificent Technologies',
        'role': 'Data Analyst Intern',
        'duration': 'Apr 2020 - Jun 2020',
        'logo': 'https://res.cloudinary.com/drlaziud8/image/upload/v1725191828/portfolio/Experience/xnapi7apg292exn5gtbh.png',
        'points': [
            'Analyzed 📊, cleaned, and processed data 📂 to facilitate training for an ML model 🤖.',
            'Corrected errors 🛠️ in Machine Learning algorithm outputs, further enhancing accuracy 🎯.'
        ],
        'url': 'https://verificient.com/',
    }
]
export const Skils = [
    {
        'name': 'HTML 5',
        'cloudinaryPath': 'portfolio/Skills/ddndfiu3ctdoydm4vwho',
        'cloudinary':'ddndfiu3ctdoydm4vwho'
    },
    {
        'name': 'CSS 3',
        'cloudinaryPath': 'portfolio/Skills/rtoq1q5tw0ulovykhdvp',
        'cloudinary':'rtoq1q5tw0ulovykhdvp'
    },
    {
        'name': 'ReactJS',
        'cloudinaryPath': 'portfolio/Skills/nsphyw7diwzon38xms44',
        'cloudinary':'nsphyw7diwzon38xms44'
    },
    {
        'name': 'NodeJs',
        'cloudinaryPath': 'portfolio/Skills/qz5egwf6dl2pkaqjw9pq',
        'cloudinary':'qz5egwf6dl2pkaqjw9pq'
    },
    {
        'name': 'JAVASCRIPT',
        'cloudinaryPath': 'portfolio/Skills/ufvehvedzrppfqfj5iz6',
        'cloudinary':'ufvehvedzrppfqfj5iz6'
    },
    {
        'name': 'Mongo DB',
        'cloudinaryPath': 'portfolio/Skills/tafdx6rimeundf0gmgm1',
        'cloudinary':'tafdx6rimeundf0gmgm1'
    },
    {
        'name': 'C',
        'cloudinaryPath': 'portfolio/Skills/C_ioozjd',
        'cloudinary':'C_ioozjd'
    },
    {
        'name': 'Python',
        'cloudinaryPath': 'portfolio/Skills/wdxkpdgbwexfbna70axm',
        'cloudinary':'wdxkpdgbwexfbna70axm'
    },
    {
        'name': 'MY Sql',
        'cloudinaryPath': 'portfolio/Skills/sl1dn5gtkgj8gqb6cjm3',
        'cloudinary':'sl1dn5gtkgj8gqb6cjm3'
    },
    {
        'name': 'GIT Hub',
        'cloudinaryPath': 'portfolio/Skills/rwbvgozcvtqghngyedkm',
        'cloudinary':'rwbvgozcvtqghngyedkm'
    },
    {
        'name': 'Figma',
        'cloudinaryPath': 'portfolio/Skills/eewwdoir5mtekbkarb5g',
        'cloudinary':'eewwdoir5mtekbkarb5g'
    },
    {
        'name': 'Eggplant',
        'cloudinaryPath': 'portfolio/Skills/nrmxph9kl418f1ovz9it',
        'cloudinary':'nrmxph9kl418f1ovz9it'
    },
    {
        'name': 'Jenkins',
        'cloudinaryPath': 'portfolio/Skills/wml4yqps3wr6lcgsdeka',
        'cloudinary':'wml4yqps3wr6lcgsdeka'
    },
    {
        'name': 'IBM RQM',
        'cloudinaryPath': 'portfolio/Skills/oftxqpntqjqnj7jcbhbj',
        'cloudinary':'oftxqpntqjqnj7jcbhbj'
    },
   
    {
        'name': 'Tailwind',
        'cloudinaryPath': 'portfolio/Skills/azepwpz9v2hjycvuvpn8',
        'cloudinary':'azepwpz9v2hjycvuvpn8'
    },
    {
        'name': 'Postman',
        'cloudinaryPath': 'portfolio/Skills/wj2e97xbfcpnvz8wqai3',
        'cloudinary':'wj2e97xbfcpnvz8wqai3'
    },
    {
        'name': 'Bootstrap',
        'cloudinaryPath': 'portfolio/Skills/lzhbdtzgo62b43qd5zrr',
        'cloudinary':'lzhbdtzgo62b43qd5zrr'
    },
    {
        'name': 'Aurdino',
        'cloudinaryPath': 'portfolio/Skills/jbzpwjrewqlpa2abz106',
        'cloudinary':'jbzpwjrewqlpa2abz106'
    }
]

export const projects = [
    {
        'name':'TraveloCity',
        'shortDescription':'Developed a comprehensive tool for travel bloggers, enabling them to seamlessly write, edit, and publish their travel experiences online, enhancing their ability to share stories with a wider audience.',
        'techStack':['HTML','CSS','JavaScript','NodeJs','Express','MongoDB'],
        'codeUrl':'https://github.com/pavanshetty-in/Travel-Blog/tree/main',
        'imageId':'inwn2mzrdbygotaipl1w',
        'imageURL':'portfolio/Projects/inwn2mzrdbygotaipl1w',
    },
    {
        'name':'IOT Smart Irrigation',
        'shortDescription':'Developed with C++, Arduino, and sensors to manage farm irrigation based on weather. The system communicated with a remote Node.js server via GSM, and data was accessible via Telegram.',
        'techStack':['C++','Arduino','NodeJs','MongoDB'],
        'codeUrl':'https://github.com/pavanshetty-in/Smart-irrigation-API',
        'imageId':'krkceicedsqv85fhhxtc',
        'imageURL':'portfolio/Projects/krkceicedsqv85fhhxtc',
    },
    {
        'name':'Landing Page',
        'shortDescription':'Developed a responsive landing page using React.js and Sass.',
        'techStack':['ReactJs','SCSS','JavaScript'],
        'codeUrl':'',
        'imageId':'kxvr8ylngmum3xnswkuk',
        'imageURL':'portfolio/Projects/kxvr8ylngmum3xnswkuk',
    },
    
]


export const ResumeDriveUrL = 'https://drive.google.com/file/d/1Lji4nEC_Loqh-bq-8FIYmpY2lZoZLnfw/preview'

