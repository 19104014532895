import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./NavBar.css";
import { CiMenuBurger } from "react-icons/ci";
const NavBar = () => {
  const [mentOpen, SetMenuOpen] = useState(false);

  const onClickSwitch = () => {
    SetMenuOpen(!mentOpen);
  };

  return (
    <nav className="flex justify-between items-center md:px-10 sticky top-0 z-50">
      <Link className="m-4 title font-semibold text-2xl" onClick={onClickSwitch} to="/">
        PavanShetty.in
      </Link>
      <div
        className="menu"
        onClick={onClickSwitch}
      >
        {/* <span></span>
        <span></span>
        <span></span> */}
        <CiMenuBurger />
      </div>
      <ul className={mentOpen ? "open" : " "}>
        <li>
          <NavLink  onClick={onClickSwitch} to="/Skills">Skills</NavLink>
        </li>
        <li>
          <NavLink   onClick={onClickSwitch} to="/Experience">Experience</NavLink>
        </li>
        <li>
          <NavLink  onClick={onClickSwitch} to="/Projects">Projects</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar;
