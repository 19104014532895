import React from "react";
import { AdvancedImage } from "@cloudinary/react";
import { GetImg } from "../../Util/GetImg.js";

const ProjectCard = ({
  name,
  shortDescription,
  techStack,
  codeUrl,
  imageURL,
}) => {


  let img = GetImg(imageURL)

  const Tags = ({ children }) => {
    return (
      <span
        className="text-[#3F83F8]"
        style={{ fontSize: "0.8rem" }}
      >{`#${children} `}</span>
    );
  };

  return (
    <div
      className=" min-h-96 xl:w-96 SkillCardShadow  items-center py-4 xl:py-6 "
      style={{ backgroundColor: "#0a0113" }}
    >

      <di className="h-64 flex flex-col justify-center">
        <AdvancedImage cldImg={img} alt={name}/>
      </di>

      <div className="flex flex-col m-6">
        <h1 className="text-white  font-bold text-start text-4xl py-2">
          {name}
        </h1>
        <p
          className="text-white text-xs text-start leading-snug"
          style={{ fontSize: "1rem" }}
        >
          {shortDescription}
        </p>
        <div className="flex-grow flex flex-row justify-between items-center pt-4">
          <div>
            {console.log(techStack)}
            {techStack.map((tech) => (
              <Tags key={tech}>{tech}</Tags>
            ))}
          </div>
          {codeUrl && (
            <a href={codeUrl} target="_blank" rel="noreferrer noopener">
              <button className="bg-white text-black px-4 py-2 rounded-md">
                View Project
              </button>
            </a>
          )}
        </div>
      </div>
      
    </div>
  );
};

export default ProjectCard;
