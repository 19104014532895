import { Cloudinary } from "@cloudinary/url-gen";

export const GetImg =(path)=>{
    const cld = new Cloudinary({
        cloud: {
          cloudName: "drlaziud8",
        },
      });
    
      const myImage = cld.image(path);

      return myImage
}